.container-products {
  width: 100%;
  height: 105%;
  padding: 10px;
  position: relative;
}
.modal-product-hide  {
  display: none;
}
.modal-product {
  overflow: scroll;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #e8e8e8;
  top: 0;
  left: 0;
}

.item-product {
  cursor: pointer;
}

.container-product {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  text-align: center;
}

.form-product {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  flex-direction: row;
  position: relative;
}

.modal-product-delete-background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(141, 141, 141, 0.186);
}

.modal-product-delete {
  top: 100px;
  margin: auto;
  width: 500px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}

.modal-product-delete .actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.product-container {
  overflow-x: hidden;
  width: 100%;
}

.product-grid-1 {
  grid-column: 1 / 6;
  grid-row: 1;
  padding-top: 10px;
}

.product-grid-2 {
  grid-column: 1;
  grid-row: 2 / 11;
  padding-top: 10px;
  max-height: 700px;
  overflow-y: scroll;
}

.product-grid-3 {
  grid-column: 2 / 4;
  grid-row: 2;
  max-height: 100px;
  padding-top: 10px;
}

.product-grid-4 {
  grid-column: 4 / 6;
  grid-row: 2;
  padding-top: 10px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-grid-5 {
  grid-column: 2 / 4;
  grid-row: 3 / 6;
  padding: 30px;
}

.product-grid-6 {
  grid-column: 4 / 6;
  grid-row: 3 / 6;
  padding: 30px;
}

.product-grid-7 {
  grid-column: 2 / 6;
  grid-row: 6;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-grid-8 {
  grid-column: 4/6;
  grid-row: 7;
  padding: 30px;
}

.product-grid-9 {
  grid-column: 2/4;
  grid-row: 7;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-grid-10 {
  grid-column: 2/6;
  grid-row: 8;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
